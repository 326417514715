import { render, staticRenderFns } from "./SettingsDialog.vue?vue&type=template&id=60a73714"
import script from "./SettingsDialog.vue?vue&type=script&lang=js"
export * from "./SettingsDialog.vue?vue&type=script&lang=js"
import style0 from "./SettingsDialog.vue?vue&type=style&index=0&id=60a73714&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports